import React from "react";
import shopImage from "../../images/Shop.png";
import souvlaki from "../../images/gyros-8051243_1280.jpg";
import { useGlobalContext } from "../../Content/Content";

import "./styles/intro.css";
function Intro() {
  const { restaurantDetails } = useGlobalContext();
  return (
    <div className="intro-logo">
      <div className="shop-details">
        <h3 className="welcome">Welcome to</h3>
        <h1 className="nameTile">Hungry Greek</h1>
        <img src={shopImage} alt="Hungry Greek shop" className="shopImage" />
        <div className="title">
          <h1 className="main-title-details">
            FREE DELIVERY <span className="tileDash"> -</span>
          </h1>
          <h1 className="main-title-details">OPEN 7 DAYS A WEEK</h1>
        </div>
        <div className="subtitleDiv">
          <h5 className="subtitle">
            MINIMUM ORDERS OVER £15 <span className="tileDash"> -</span>
          </h5>
          <h5 className="subtitle">SMALLER ORDERS MAY CHARGE £1.</h5>
        </div>
        <h1 className="openHours">OPENING HOURS:</h1>
        <h4 className="hours">
          EVERYDAY {restaurantDetails.openHour} PM TILL{" "}
          {Number(restaurantDetails.closeHour.split(":")[0]) - 12}:
          {restaurantDetails.closeHour.split(":")[1]} PM
        </h4>
      </div>
      <img src={souvlaki} alt="Hungry Greek shop" className="souvlakiImage" />
    </div>
  );
}

export default Intro;
