import React from "react";
import "./styles/menu.css";
import souvlakia from "../../images/greek-food-4343873_1920.jpg";
import gyros from "../../images/gyros-4230720_1280.jpg";
import salata from "../../images/salad-3595559_1280.jpg";
import { useGlobalContext } from "../../Content/Content";

function Menu() {
  const { setOpenModal } = useGlobalContext();
  return (
    <div className="menu tripStyle">
      <h1 className="menuTitle">
        Our Menu: <br />
        <span className="tagline">The Ultimate Odyssey of Greek Tastes</span>
      </h1>
      <div className="menuDetails">
        <div className="menuDetailsImages">
          <img
            src={souvlakia}
            alt="Hungry Greek souvlakia"
            className="menuDetailsImagesSouvlakia"
          />
          <img
            src={gyros}
            alt="Hungry Greek gyros"
            className="menuDetailsImagesGyros"
          />
        </div>
        <div className="menuRightSide">
          <div className="paragraph">
            <p className="paragraphDetails">
              Welcome to Hungry Greek, the culinary oasis where the rich
              traditions of Greek cuisine meet modern dining in a symphony of
              flavors. From the time-honored classics like moussaka and souvlaki
              to innovative takes on Mediterranean dishes, every plate invites
              you on a unique gastronomic adventure. Indulge in the vibrant,
              robust flavors that only the freshest ingredients can provide, all
              in an ambiance that feels like a Grecian paradise. Come savor the
              ultimate Greek dining experience.
            </p>
            <button
              className="menuButton"
              onClick={() => {
                setOpenModal(true);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Menu
            </button>
          </div>
          <img
            src={salata}
            alt="Hungry Greek salata"
            className="menuDetailsImagesSalata"
          />
        </div>
      </div>
    </div>
  );
}

export default Menu;
