import React, { useState, useEffect } from "react";
import "./styles/footer.css";
import footerImg from "../../images/pc-image-back.png";
import phoneImage from "../../images/new-image-back.png";
import footerImgTakeAway from "../../images/restaurant-2577685_1280.jpg";
import { useGlobalContext } from "../../Content/Content";

function Footer() {
  const { openModal, scrollToContact } = useGlobalContext();
  const [photoImg, setPhotoImg] = useState(false);

  useEffect(() => {
    // Function to handle window resizing
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setPhotoImg(true);
      } else {
        setPhotoImg(false);
      }
    };
    // Initial check
    handleResize();
    // Attach event listener
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="footerMain">
      {photoImg ? (
        <img src={phoneImage} alt="footer-Images" className="footerImage" />
      ) : (
        <img src={footerImg} alt="footer-Images" className="footerImage" />
      )}
      <div className="footer">
        <div className="footerLeft">
          <div className="footerDetails ">
            <h1>
              ORDER &<br />
              COLLECT
            </h1>
          </div>
          <div className="emptyDiv"></div>
        </div>
        <div className="footerRight tripStyleDark">
          <p className="orderCollectDetails">
            Experience the joys of Greek cuisine from the comfort of your home
            with our seamless Order and Collect service. At Hungry Greek, we
            extend the magic of our dining room to your doorstep. Each dish is
            meticulously prepared to order, ensuring the same freshness and
            quality you've come to expect from our restaurant. Packaged with
            care to preserve the integrity of flavors, your meal will be ready
            for prompt collection at your convenience. Trust us to turn every
            Order and Collect experience into an occasion to remember. Because
            at Hungry Greek, excellence is not a mere word; it's our commitment
            to you.
          </p>
          <button className="orderButton" onClick={scrollToContact}>
            Order
          </button>
          <img
            src={footerImgTakeAway}
            alt="footer-Images-take-away"
            className="footerImage-take-away"
          />
        </div>
      </div>
      <div
        className="endFooter tripStyle"
        style={openModal ? { height: "500px" } : { height: "50px" }}
      ></div>
    </div>
  );
}

export default Footer;
