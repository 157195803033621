import React from 'react';

function Loading() {
  return (
    <div className='middleLoading'>
      <div className='loadingBar loadingBar1'></div>
      <div className='loadingBar loadingBar2'></div>
      <div className='loadingBar loadingBar3'></div>
      <div className='loadingBar loadingBar4'></div>
      <div className='loadingBar loadingBar5'></div>
      <div className='loadingBar loadingBar6'></div>
      <div className='loadingBar loadingBar7'></div>
      <div className='loadingBar loadingBar8'></div>
    </div>
  );
}

export default Loading;
