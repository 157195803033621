import React, { useState } from "react";
import logo from "../images/HUNGRY_GREEK-logo.png";
import "./styles/navigator.css";
import { useGlobalContext } from "../Content/Content";

function Navigator() {
  const { setOpenModal, scrollToContact, scrollToAboutUs } = useGlobalContext();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="navigator">
      <img
        src={logo}
        alt="Hungry Greek Logo"
        className="logo"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      />
      <div>
        <button
          className={`menu-button ${isOpen ? "open" : ""}`}
          onClick={() => {
            toggleMenu();
            setOpenModal(false);
          }}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
        <div className={`menu-container ${isOpen ? "open" : ""}`}>
          <ul className="menu-list">
            <li
              onClick={() => {
                toggleMenu();
                setOpenModal(true);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Menu
            </li>
            <li
              onClick={() => {
                toggleMenu();
                setOpenModal(false);
                scrollToContact();
              }}
            >
              Order
            </li>
            <li
              onClick={() => {
                toggleMenu();
                setOpenModal(false);
                scrollToAboutUs();
              }}
            >
              About Us
            </li>
          </ul>
          <img src={logo} alt="Hungry Greek Logo" className="menu-image" />
        </div>
      </div>
    </nav>
  );
}

export default Navigator;
