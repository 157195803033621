/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./styles/contact.css";
import { useGlobalContext } from "../../Content/Content";

function Contact() {
  const { contactRef, restaurantDetails } = useGlobalContext();
  return (
    <article className="Contact">
      <h2 className="ContactMainTitle">Find us and order:</h2>
      <div className="ContactDiv">
        <section className="contactMap">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2375.883158254238!2d-2.99313982250789!3d53.45267487232032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b23f030f99be3%3A0x4eff9ad6b94df433!2s308%20Stanley%20Rd%2C%20Bootle%20L20%203ET!5e0!3m2!1sen!2suk!4v1696454889074!5m2!1sen!2suk"
                // width="500"
                // height="600"
                allowfullscreen=""
                style={{ border: "none" }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="shop_maps"
              ></iframe>
              <br />
            </div>
          </div>
        </section>
        <section className="contactForm" ref={contactRef}>
          <div className="underline"></div>
          <h2 className="contactAddress">ADDRESS</h2>
          <h3 className="contactAddressDetail">
            308 Stanley Rd, <br />
            Bootle L20 3ET
          </h3>
          <div className="underline"></div>
          <h1 className="contactOpenHours">OPENING HOURS:</h1>
          <h4 className="contactHours">
            EVERYDAY {restaurantDetails.openHour} PM TILL{" "}
            {Number(restaurantDetails.closeHour.split(":")[0]) - 12}:
            {restaurantDetails.closeHour.split(":")[1]} PM
          </h4>
          <div className="underline"></div>
          <div className="contactTitle">
            <div className="deliveryOpenDays">
              <h1 className="contactMain-title-details">
                FREE DELIVERY<spam className="deliveryOpenDaysDash"> -</spam>
              </h1>
              <h1 className="contactMain-title-details">OPEN 7 DAYS A WEEK</h1>
            </div>
          </div>
          <div className="deliveryOpenDaysSubtitle">
            <h5 className="contactSubtitle">
              MINIMUM ORDERS OVER £15
              <spam className="deliveryOpenDaysDash"> -</spam>
            </h5>
            <h5 className="contactSubtitle">SMALLER ORDERS MAY CHARGE £1.</h5>
          </div>
          <div className="underline"></div>
          <h2 className="contactAddress">CONTACT</h2>
          <a
            href={`tel:${restaurantDetails.phone}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: "white", textDecoration: "none" }}
          >
            <h3 className="contactDetails">
              Tel:{" "}
              <span className="numberColor"> {restaurantDetails.phone}</span>
            </h3>
          </a>
          <h3 className="contactDetails">Email: hungrygreek308@gmail.com</h3>
          <div className="underline"></div>
          <h2 className="contactAddress">Order Your Favorites</h2>
          <div className="orderMethodsApps">
            <a
              href="https://www.ubereats.com/gb/store/hungry-greek/cKgllUNvRPSjHAVnS-c3SA"
              target="_blank"
              rel="noreferrer"
              className="orderMethodsUberEat"
            >
              <div>
                Uber
                <br />
                <span className="UberStyle">Eats</span>
              </div>
            </a>
            <a
              href="https://www.just-eat.co.uk/restaurants-hungry-greek-bootle/menu?serviceType=collection&utm_source=google&utm_medium=organic&utm_campaign=orderactionv2"
              target="_blank"
              rel="noreferrer"
              className="orderMethodsJustEat"
            >
              <div>
                Just
                <br />
                <span className="JustEatStyle">Eat</span>
              </div>
            </a>
            <a
              href={`tel:${restaurantDetails.phone}`}
              target="_blank"
              rel="noreferrer"
              className="orderMethodsPhone"
            >
              <div>
                Phone
                <br />
                <span className="OrderStyle">Order</span>
              </div>
            </a>
          </div>
        </section>
      </div>
    </article>
  );
}

export default Contact;
