import React from "react";
import gyros from "../../images/kebob-3682282_1280.jpg";
import souvlakia from "../../images/gyros-4340006_1280.jpg";
import "./styles/aboutUs.css";
import { useGlobalContext } from "../../Content/Content";
function AboutUs() {
  const { aboutUsRef } = useGlobalContext();

  return (
    <div className="aboutUs tripStyle" ref={aboutUsRef}>
      <div className="leftDiv">
        <h1 className="menuTitle">
          About Us: <br />
          <span className="tagline">
            Discover the Soul of Greece on Every Plate
          </span>
        </h1>
        <p className="aboutUsDetails">
          Nestled in the heart of Liverpool, Hungry Greek is not just a
          restaurant; it's a journey to the islands of Greece. Our roots trace
          back to a quaint little town in Greece, where our founders first
          discovered the joy of authentic Greek cooking. With recipes passed
          down through generations, we bring the quintessential Greek dining
          experience to you. Every dish tells a story, from the locally-sourced
          ingredients to the hands that expertly craft them into culinary
          masterpieces. At Hungry Greek, you're not just a customer; you're
          family.
        </p>
        <img src={gyros} alt="Hungry Greek gyros" className="aboutUsGyros" />
      </div>
      <div className="rightSide">
        <img
          src={souvlakia}
          alt="Hungry Greek gyros"
          className="aboutUsSouvlakia"
        />
      </div>
    </div>
  );
}

export default AboutUs;
