import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import foodData from "./foodData";

// import { useLocation } from "react-router-dom";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const contactRef = useRef(null);
  const aboutUsRef = useRef(null);
  const [products, setProducts] = useState(foodData);
  const [restaurantDetails, setRestaurantDetails] = useState({
    openHour: "12:00",
    closeHour: "23:00",
    phone: "01513941648",
  });
  // const fetchData = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       "https://order-app-api-0krx.onrender.com/api/v1/product"
  //     );

  //     const { data: getStoreDetails } = await axios.get(
  //       "https://order-app-api-0krx.onrender.com/api/v1/settings/user-settings"
  //     );

  //     setRestaurantDetails(getStoreDetails.data);
  //     setProducts(data.product);
  //   } catch (error) {
  //     setProducts(foodData);
  //     setRestaurantDetails({
  //       openHour: "12:00",
  //       closeHour: "23:00",
  //       phone: "01513941648",
  //     });
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAboutUs = () => {
    aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <AppContext.Provider
      value={{
        openModal,
        contactRef,
        aboutUsRef,
        products,
        restaurantDetails,
        setRestaurantDetails,
        setProducts,
        setOpenModal,
        scrollToContact,
        scrollToAboutUs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
