import React, { useEffect, useState } from "react";
import "./styles/foodMenu.css";
import { AiOutlineArrowUp } from "react-icons/ai";
import { useGlobalContext } from "../../Content/Content";

function FoodMenu() {
  const { setOpenModal, products, restaurantDetails } = useGlobalContext();
  const [isVisible, setIsVisible] = useState(false);

  // Group menu items by category
  const groupedByCategory = products.reduce((acc, item) => {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push(item);
    return acc;
  }, {});

  // Show button when page is scrolled down by more than 200px
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Smooth scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <menu className="FoodMenu">
      <button className="close-button" onClick={() => setOpenModal(false)}>
        <div className="close-icon">
          <div className="close-line close-line1"></div>
          <div className="close-line close-line2"></div>
        </div>
      </button>
      <div className="scroll-to-top">
        {isVisible && (
          <button onClick={scrollToTop} className="scroll-to-top__button">
            <AiOutlineArrowUp />
          </button>
        )}
      </div>
      <h1 className="ourMenu">Our Menu</h1>

      <h3 className="FoodMenuOpenHours">Opening Hours:</h3>
      <p className="FoodMenuHours">
        Everyday {restaurantDetails.openHour} PM till{" "}
        {Number(restaurantDetails.closeHour.split(":")[0]) - 12}:
        {restaurantDetails.closeHour.split(":")[1]} PM
      </p>
      <div className="menuContainer">
        {Object.keys(groupedByCategory).map((category, index) => (
          <div key={index}>
            <div className="lineTitleMenu">
              <div className="lineTitle"></div>
              <div className="titleSection">{category}</div>
              <div className="lineTitle"></div>
            </div>
            {groupedByCategory[category].map((item, index) => (
              <div key={item.id}>
                <div className="foodNameDiv">
                  <div className="foodNumberName">{`${index + 1}. ${
                    item.name
                  }`}</div>
                  <div className="foodPrice">
                    £{Number(item.price).toFixed(2)}
                  </div>
                </div>
                <div className="foodIngredients">{item.description}</div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div style={{ height: "100px" }}></div>
    </menu>
  );
}

export default FoodMenu;
