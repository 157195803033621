import React from "react";
import "./LoadingSpinner.css";
function LoadingSpinner() {
  return (
    <article className="LoadingSpinnerArticle">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </article>
  );
}

export default LoadingSpinner;
