// images.js
export const imageList = [
  require("./images/HUNGRY_GREEK-logo.png"),
  require("./images/HUNGRY_GREEK.png"),
  // require("./images/Image-footer.jpg"),
  require("./images/Shop.png"),
  require("./images/error-404-g28f5b5727_1920.png"),
  // require("./images/footer-image.jpg"),
  require("./images/greek-food-4343873_1920.jpg"),
  require("./images/gyros-4230720_1280.jpg"),
  require("./images/gyros-4340006_1280.jpg"),
  require("./images/gyros-8051243_1280.jpg"),
  require("./images/kebob-3682282_1280.jpg"),
  require("./images/menu.png"),
  require("./images/paper-413882_1280.jpg"),
  require("./images/paper-dark.jpg"),
  require("./images/restaurant-2577685_1280.jpg"),
  require("./images/salad-3595559_1280.jpg"),
  require("./images/souvlaki-1649221_1280.jpg"),
  // ... add more images
];
