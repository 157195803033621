const foodData = [
  // SALAD
  {
    id: 1,
    category: "SALAD",
    name: "GREEK SALAD",
    price: "6.00",
    description:
      "Tomatoes, Cucumber, Green Pepper, olive, onion, feta cheese, capers, olive oil, oregano",
  },
  {
    id: 2,
    category: "SALAD",
    name: "DAKSO SALAD",
    price: "5.50",
    description:
      "Cracker broad, grated feta cheese, tomatoes, onions, fresh parsley, olive oil",
  },
  {
    id: 3,
    category: "SALAD",
    name: "CHICKEN CESAR SALAD",
    price: "8.00",
    description: "Lettuce, crouton, cherry tomatoes, parmesan, cesar dressing",
  },

  // SOUVLAKI PITA WRAP
  {
    id: 4,
    category: "SOUVLAKI PITA WRAP",
    name: "PORK GYROS",
    price: "8.00",
    description: "Onion, tomatoes, tzatziki, fries",
  },
  {
    id: 5,
    category: "SOUVLAKI PITA WRAP",
    name: "CHICKEN GYROS",
    price: "8.00",
    description: "Lettuce, tomatoes, special sauce, fries",
  },
  {
    id: 6,
    category: "SOUVLAKI PITA WRAP",
    name: "PORK KALAMAKI",
    price: "8.00",
    description: "Onion, tomatoes, tzatziki, fries",
  },
  {
    id: 7,
    category: "SOUVLAKI PITA WRAP",
    name: "CHICKEN KALAMAKI",
    price: "8.00",
    description: "Lettuce, tomatoes, tzatziki, fries",
  },
  {
    id: 8,
    category: "SOUVLAKI PITA WRAP",
    name: "SAUSAGE KALAMAKI",
    price: "8.00",
    description: "Onion, tomatoes, tzatziki, fries",
  },
  {
    id: 9,
    category: "SOUVLAKI PITA WRAP",
    name: "KEBAB KALAMAKI",
    price: "8.00",
    description: "Onion, tomatoes, tzatziki, fries",
  },
  {
    id: 10,
    category: "SOUVLAKI PITA WRAP",
    name: "HALLOUMI KALAMAKI",
    price: "8.00",
    description: "Onion, tomatoes, tzatziki, fries",
  },
  {
    id: 11,
    category: "SOUVLAKI PITA WRAP",
    name: "VEGAN SOUVLAKI",
    price: "8.00",
    description: "Tomatoes, Humus, onion, veggie mix, fries",
  },

  // MERIDES OR PORTIONS
  {
    id: 12,
    category: "MERIDES OR PORTIONS",
    name: "PORK GYROS",
    price: "13.00",
    description: "Pita, tomatoes, onions, tzatziki, fresh fries",
  },
  {
    id: 13,
    category: "MERIDES OR PORTIONS",
    name: "CHICKEN GYROS",
    price: "13.00",
    description: "Pita, special sauce, lettuce, tomatoes, fresh fries",
  },
  {
    id: 14,
    category: "MERIDES OR PORTIONS",
    name: "CHICKEN KALAMAKI",
    price: "13.00",
    description: "Pita, tomatoes, onions, tzatziki, fresh fries",
  },
  {
    id: 15,
    category: "MERIDES OR PORTIONS",
    name: "PORK KALAMAKI",
    price: "13.00",
    description: "Pita, tomatoes, onions, tzatziki, fresh fries",
  },

  // SKEPASTI
  {
    id: 16,
    category: "SKEPASTI",
    name: "SAUSAGE KALAMAKI",
    price: "13.00",
    description: "Pita, tomatoes, onions, tzatziki, fresh fries",
  },
  {
    id: 17,
    category: "SKEPASTI",
    name: "KEBAB KALAMAKI",
    price: "13.00",
    description: "Pita, tomatoes, onion, tzatziki, fresh fries",
  },
  {
    id: 18,
    category: "SKEPASTI",
    name: "HALLOUMI KALAMAKI",
    price: "13.00",
    description: "Pita, tomatoes, onion, tzatziki, fresh fries",
  },
  {
    id: 19,
    category: "SKEPASTI",
    name: "PORK GYROS",
    price: "12.00",
    description: "Onion, tomatoes, tzatziki, fries, grated cheese",
  },
  {
    id: 20,
    category: "SKEPASTI",
    name: "CHICKEN GYROS",
    price: "12.00",
    description: "Lettuce, Tomatoes, special sauce, fries, grated cheese",
  },
  {
    id: 21,
    category: "SKEPASTI",
    name: "HALLOUMI SKEPASTI",
    price: "12.00",
    description:
      "Lettuce, tomatoes, special sauce, fries, grated cheese, hummus, mix veg, onion, fries",
  },
  {
    id: 22,
    category: "SKEPASTI",
    name: "VEGETARIAN SKEPASTI",
    price: "12.50",
    description: "Humus, mix veg, onion, fries",
  },

  // KALAMAKIA OR SKEWERS
  {
    id: 23,
    category: "KALAMAKIA OR SKEWERS",
    name: "PORK",
    price: "3.00",
    description: "Served with lemon, oregano, bread",
  },
  {
    id: 24,
    category: "KALAMAKIA OR SKEWERS",
    name: "CHICKEN",
    price: "3.00",
    description: "Served with lemon, oregano, bread",
  },
  {
    id: 25,
    category: "KALAMAKIA OR SKEWERS",
    name: "VILLAGE SAUSAGE",
    price: "3.00",
    description: "Served with lemon, oregano, bread",
  },
  {
    id: 26,
    category: "KALAMAKIA OR SKEWERS",
    name: "KEBAB",
    price: "3.00",
    description: "Served with lemon and bread",
  },

  // BURGERS
  {
    id: 27,
    category: "BURGERS",
    name: "GREEK BURGER",
    price: "7.00",
    description:
      "Fresh homemade beef burger, onion, tomato, tzatziki, grated cheese. Served with fresh fries.",
  },

  {
    id: 28,
    category: "BURGERS",
    name: "CHICKEN BURGER",
    price: "8.00",
    description:
      "Chicken breast, lettuce, tomatoes, onion, special homemade sauce. Served with fresh fries.",
  },

  // MIX PLATE
  {
    id: 29,
    category: "MIX PLATE",
    name: "MIX PLATE (FOR TWO PEOPLE)",
    price: "24.90",
    description:
      "2 Pitta bread, 1 Lukaniko sauce, 1 greek kebab, 1 Chicken & 1 Pork skeweres, chicken & pork gyros, Fries. Come with starters, Tzatziki, tirokafteri and Greek Salad!",
  },

  {
    id: 30,
    category: "MIX PLATE",
    name: "MIX PLATE (FOR FOUR PEOPLE)",
    price: "44.90",
    description:
      "4 pitta bread, 2 lukaniko sauce, 2 greek kebab, 2 chicken & 2 pork skeweres, chicken & pork gyros, fries. come with starters, tzatziki, tirokafteri and Greek Salad!",
  },

  // DIPS
  {
    id: 31,
    category: "DIPS",
    name: "TZATZIKI",
    price: "3.50",
    description: "",
  },
  {
    id: 32,
    category: "DIPS",
    name: "SPECIAL SAUCE",
    price: "3.50",
    description: "",
  },
  {
    id: 33,
    category: "DIPS",
    name: "HUMMUS",
    price: "3.50",
    description: "",
  },
  {
    id: 34,
    category: "DIPS",
    name: "SPICY FETA TIROKAFTERI",
    price: "4.50",
    description: "",
  },

  // SIDES
  {
    id: 35,
    category: "SIDES",
    name: "PITTA BREAD",
    price: "1.00",
    description: "",
  },
  {
    id: 36,
    category: "SIDES",
    name: "FRIES",
    price: "3.50",
    description: "",
  },
  {
    id: 37,
    category: "SIDES",
    name: "GREEK OLIVES",
    price: "3.00",
    description: "",
  },
  {
    id: 38,
    category: "SIDES",
    name: "FETA CHEESE",
    price: "4.00",
    description: "",
  },
  {
    id: 39,
    category: "SIDES",
    name: "FRIES WITH FETA",
    price: "5.00",
    description: "",
  },

  // DESSERTS
  {
    id: 40,
    category: "DESSERTS",
    name: "BAKLAVA",
    price: "5.00",
    description: "",
  },
  {
    id: 41,
    category: "DESSERTS",
    name: "KATAIFI",
    price: "5.00",
    description: "",
  },
  {
    id: 42,
    category: "DESSERTS",
    name: "KARIDOPITA",
    price: "5.00",
    description: "",
  },

  // DRINKS
  {
    id: 43,
    category: "DRINKS",
    name: "SOFT DRINK",
    price: "2.00",
    description: "",
  },
  {
    id: 44,
    category: "DRINKS",
    name: "GREEK BEER",
    price: "3.50",
    description: "",
  },
];

export default foodData;
